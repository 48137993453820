import React from 'react';
import preview_003 from './img/preview_003.png';
import preview007 from './img/007.png'
import preview058 from './img/058.png'

function App() {
  return (

    <div style={{ backgroundColor: '#231f20' }}>
    <div className="container-fluid bg-transparent">
      <div className="row justify-content-center">
        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 text-left mt-2">
    <img className="img-fluid" src={preview007} alt="Together" />

<hr className="img-fluid d-none d-lg-block" />
<img className="img-fluid d-none d-lg-block" src={preview058} alt="Together" />
         <hr />
         <h1>Brief:</h1>
          <hr />
          <p>
            Riddled along the highways and byways of America, gas stations typify mass production and commercial uniformity. In 1964, <a href="https://en.wikipedia.org/wiki/Edward_Ruscha" target="_blank" rel="noopener noreferrer">Ed Ruscha</a> turned this ubiquity into something unique. <a href="https://www.moma.org/collection/works/76637" target="_blank" rel="noopener noreferrer"><i>Standard Station</i></a>'s transformation of the commonplace typifies             <a href="https://en.wikipedia.org/wiki/Web3" target="_blank" rel="noopener noreferrer">Web3</a>. Both signify a pivotal shift in perception, veering from physical uniqueness to conceptual distinctiveness. Where originality retains its worth in an age of effortless replication.
          </p>

          <p>
            Though not wholly original, Gas Guzzlers is conceptually distinct. A play on the subjective nature of meaning, re-inventing Ruscha's work for a Web3 era. One in which the term '<a href="https://ethereum.org/en/developers/docs/gas/" target="_blank" rel="noopener noreferrer">gas</a>' has a new connotation.
          </p>

          <p>
            In contrast to its traditional meaning, gas on the blockchain is a virtual concept. While both are crucial for day-to-day operations, their natures highlight a fascinating divergence in how the same term adapts to vastly different contexts.
          </p>

          <p>
            Just as <a href="https://en.wikipedia.org/wiki/Standard_Oil" target="_blank" rel="noopener noreferrer">Standard Oil</a> in the 1960s was synonymous with gasoline, representing a widely recognized gas station that consumed significant physical fuel, <a href="https://etherscan.io/gastracker" target="_blank" rel="noopener noreferrer">contracts on the Ethereum blockchain have achieved similar iconic status</a>, consistently consuming more virtual gas.</p>

          <p>
            This original analogy via the playful parody of Ruscha's work is the unique focus of Gas Guzzlers, a collection of 512 generative NFTs created by <a href="https://art101.io" target="_blank" rel="noopener noreferrer">ART101</a>.
          </p>
<div style={{ marginTop: '16px' }}></div>
       
            <img className="img-fluid" src={preview_003} alt="Together"/>
           


        </div>
      </div>
    </div>
    </div>
  );
}

export default App;
