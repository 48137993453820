import React from 'react';
import preview from './img/preview_004.png';
import logoCartyisme from './img/101.svg'; // Make sure the path to your image is correct

function App() {
  return (

    <div style={{ backgroundColor: '#231f20' }}>
    <div className="container-fluid bg-transparent">
      <div className="row justify-content-center">

        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 text-left mx-2">
            <img className="img-fluid" src={preview} alt="Together" />
        </div>
        <div className="col-xs-8 col-sm-8 col-md-6 col-lg-4 col-xl-4 text-center">
          <br />
          <a href="https://art101.io/" target="_blank" rel="noopener noreferrer">
            <img className="img-fluid" src={logoCartyisme} style={{ height: '96px' }} alt="Cartyisme Logo" />
          </a>
          <br /><br />
          <div style={{ marginTop: '16px' }}></div>
        </div>
      </div>
    </div>
    </div>

  );
}

export default App;
