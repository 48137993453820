import React from 'react';
import gasImage from './img/gas.svg'; // Ensure the image is correctly imported
import gasLogo from './img/gas_logo.svg';
import NFTTotalSupply from './NFTTotalSupply';


function App() {
  return (

    <div style={{ backgroundColor: '#231f20' }}>
      <div style={{ paddingTop: '24px' }}></div>

      <div className="container-fluid bg-transparent">
        <div className="row justify-content-center">
          <div className="col-xs-8 col-sm-8 col-md-6 col-lg-4 col-xl-4 text-center">
            <img className="img-fluid" src={gasImage} style={{ height: '96px' }} />
<div style={{ marginTop: '16px' }}></div>

<a href="https://mint.fun/ethereum/0x87739a10f06c08468206008f6cf1abab8e6e9a0d" className="Mutt_Button_Alt" target="_blank" ><b>MINT @ MINT.FUN</b></a>
<a href="https://catchmint.xyz/collection/ethereum/0x87739a10f06c08468206008f6cf1abab8e6e9a0d" className="Mutt_Button_Alt" target="_blank" ><b>MINT @ CATCHMINT.XYZ</b></a>
<a href="https://pro.opensea.io/mints?address=0x87739a10f06c08468206008f6cf1abab8e6e9a0d" className="Mutt_Button_Alt" target="_blank" ><b>MINT @ PRO.OPENSEA.IO</b></a>
            <div style={{ marginTop: '16px' }}></div>
<p>
              A 512 Generative <a href="https://www.coingecko.com/en/coins/ethereum" target="_blank" rel="noopener noreferrer">$ETH</a> NFT Parody of <a href="https://en.wikipedia.org/wiki/Edward_Ruscha" target="_blank" rel="noopener noreferrer">Ed Ruscha</a>'s <a href="https://www.moma.org/collection/works/76637" target="_blank" rel="noopener noreferrer">
                <i>Standard Station</i></a> created by <a href="https://twitter.com/art101nft" target="_blank" rel="noopener noreferrer">ART101</a>.</p>










            
            <p className="my-n1">.010Ξ each</p>
            
        <NFTTotalSupply />
          
<p><a className="mb-n4" href="https://opensea.io/collection/gasguzzlers" target="_blank" >Gas Guzzlers on OpenSea</a></p>
        
          </div>

        </div>
      </div>
    </div>













  );
}

export default App;
