import React from 'react';
import gasAltImage from './img/gas_alt.svg'; // Make sure the path to your image is correct
import os from './img/os_alt.svg'
import etherscan from './img/etherscan_alt.svg'
import x from './img/x_alt.svg'
import mintfun from './img/mintfun_alt.svg'

function YourComponent() {
  return (
    <div style={{ backgroundColor: '#f0f0f0' }} >
    <div className="container-fluid bg-transparent">
      <div className="row inner align-items-center">
        <div className="col-12 text-center">
          <div style={{ marginTop: '24px' }}></div>
          <img className="img-fluid" src={gasAltImage} style={{ height: '96px' }} alt="Gas Alt" />


<div style={{ marginTop: '16px' }}></div>
          <p className="" style={{ color: '#231f20' }}><b>Contract Address: <a href="https://etherscan.io/address/0x87739a10f06c08468206008f6cf1abab8e6e9a0d#writeContract#F5"  className="footlink" target="_blank" rel="noopener noreferrer">0x87739a...6e9a0d</a></b></p>


          <div style={{ marginTop: '16px' }}></div>
            <a href="https://mint.fun/ethereum/0x87739a10f06c08468206008f6cf1abab8e6e9a0d" target="_blank"  rel="noopener noreferrer">
              <img className="img-fluid" src={mintfun}  style={{ height: '32px', marginRight: '24px' }}/>
            </a>


            <a href="https://etherscan.io/address/0x87739a10f06c08468206008f6cf1abab8e6e9a0d#writeContract#F5" target="_blank"  rel="noopener noreferrer">
              <img className="img-fluid" src={etherscan}  style={{ height: '32px', marginRight: '24px' }}/>
            </a>

            
            <a href="https://opensea.io/collection/gasguzzlers" target="_blank"  rel="noopener noreferrer">
              <img className="img-fluid" src={os}  style={{ height: '32px', marginRight: '24px' }}/>
            </a>


            <a href="https://twitter.com/art101nft" target="_blank" rel="noopener noreferrer">
              <img className="img-fluid" src={x}  style={{ height: '32px' }}/>
            </a>
          <div style={{ marginTop: '24px' }}></div>

        </div>
      </div>
    </div>
    </div>
  );
}

export default YourComponent;
