import React, { Component } from 'react'; // Correctly import Component
import Web3 from 'web3';
import axios from 'axios';
import ReactDOM from "react-dom/client";
import Nav from "./Nav";
import Splash from "./Splash";
import Info from "./Info";
import Blurb from "./Blurb";
import Mint from "./Mint";
import Faq from "./Faq";
import Cartyisme from "./Cartyisme";
import Footer from "./Footer";

class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <Nav />
        <Splash />
        <Info />
        <Blurb />
        <Mint />
        <Faq />
        <Cartyisme />
        <Footer />
      </React.StrictMode>
    );
  }
}

export default App;
