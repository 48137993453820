import React from 'react';
import preview_001 from './img/splash.png';
import gasLogo from './img/gas_logo.svg';



function App() {
  return (

    <div style={{ backgroundColor: '#231f20' }}>
    <div className="container-fluid bg-transparent">
      <div className="row justify-content-center">

               <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 text-left mx-2">
               <hr />
         
            <img className="img-fluid" src={preview_001} alt="Together"  id="mint"/>
            <div style={{ marginTop: '16px' }} ></div>
        </div>



      </div>
    </div>
    </div>
  );
}

export default App;
