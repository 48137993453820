import React from 'react';
import preview_004 from './img/preview.png';

function App() {
  return (

    <div style={{ backgroundColor: '#231f20' }}>
    <div className="container-fluid bg-transparent">
      <div className="row justify-content-center">
        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 text-left mt-n3">
          <hr />

          <h1>FAQ:</h1>
          <hr />
          <br />

          <h5><b>What is ART101?</b></h5>
          <p>
           <a href="https://art101.io/" target="_blank" rel="noopener noreferrer">Art101</a> is the <i>original free-to-mint NFT project</i>, founded in the summer of 2021, this is our first fixed cap paid generative collection. <a href="https://art101.io/" target="_blank" rel="noopener noreferrer">ART101</a>'s lowest fixed-supply generative collection to date.
          </p>
          <br />
          <h5><b>Supply?</b></h5>
          <p>512</p>
          <br />
          <h5><b>Wen mint?</b></h5>
          <p>Gas Guzzlers is a stealth drop, mint is TBA. Follow us on X, at <a href="https://twitter.com/art101nft" target="_blank" rel="noopener noreferrer">@ART101NFT</a>, for updates.</p>
          <br />
          <h5><b>Price?</b></h5>
          <p>.010Ξ each</p>
          <br />
          <h5><b>How many Gas Guzzlers can I mint?</b></h5>
          <p>Gas Guzzlers' mint has a limit of 5 per wallet.</p>
          <br />
          <h5><b>Is each Gas Guzzlers NFT unique?</b></h5>
          <p>
          Yes, Gas Guzzlers NFTs are <a href="https://en.wikipedia.org/wiki/Generative_art" target="_blank" rel="noopener noreferrer">generative</a>, consisting of 21 traits distributed over 512 distinct images. 
          </p>
          <br />
          <h5><b>What format/resolution are Gas Guzzlers NFTs?</b></h5>
          <p>
          Each Gas Guzzlers NFT is a print-ready 6295 x 3525 300PPI PNG file, and includes an <a href="https://en.wikipedia.org/wiki/SVG" target="_blank" rel="noopener noreferrer">infinitely scalable vector</a> original.
          </p>
          <br />
          <h5><b>Utility?</b></h5>
          <p>Nope, just art. More to come.</p>
          <br />
          <h5><b>Is there a Discord?</b></h5>
          <p>
            Yes, join the <a href="https://discord.gg/T9MRVQqPzH" target="_blank" rel="noopener noreferrer">Art101 Discord</a>, or follow us on X <a href="https://twitter.com/art101nft" target="_blank" rel="noopener noreferrer">@ART101NFT</a>.
          </p>
         
<div style={{ marginTop: '16px' }}></div>

           <img className="img-fluid" src={preview_004} alt="Together"  id="mint"/>
           <div style={{ marginTop: '16px' }}></div>
          <hr />
        </div>
      </div>
    </div>
    </div>

  );
}

export default App;
