import './index.css';
import gas from './img/gas.svg'
import os from './img/os.svg'
import etherscan from './img/etherscan.svg'
import x from './img/x.svg'
import mintfun from './img/mintfun.svg'

function App() {
  return (
    <nav className="sticky-top" style={{ marginTop: '2px !important' }}>
      <div className="container-fluid pb-4" style={{ marginTop: '8px !important' }}>
        <div className="row ">
          <div className="col-6 text-left justify-content-start">
            <img className="img-fluid mt-1" src={gas} style={{ height: '32px' }} />
          </div>
          <div className="col-6 text-right pt-1">
                      <a href="https://opensea.io/collection/gasguzzlers" target="_blank">
              <img className="img-fluid" src={os}  style={{ height: '32px', marginRight: '8px' }}/>
            </a>


            <a href="https://etherscan.io/address/0x87739a10f06c08468206008f6cf1abab8e6e9a0d#writeContract#F5" target="_blank">
              <img className="img-fluid" src={etherscan}  style={{ height: '32px', marginRight: '8px' }}/>
            </a>






            <a href="https://twitter.com/art101nft" target="_blank">
              <img className="img-fluid" src={x}  style={{ height: '32px' }}/>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default App;
